import React from "react"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import Hero from "../components/home/hero"
import { HeroSection } from "../components/landingPage/HeroSection"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { graphql } from "gatsby"
import { HomeWebAppsZapierImage } from "../components/home/HomeWebAppsZapierImage"
import { Helmet } from "react-helmet"

const useStyles = makeStyles(theme => ({
  mainArea: {
    marginBottom: "60px",
  },
  h2: {
    textAlign: "center",
    color: theme.palette.primary.main,
    marginTop: "50px",
    marginBottom: "20px",
    fontSize: "1.75rem",
    fontWeight: "600",
  },
  list: {
    listStyle: "circle",
  },
  textRight: {
    fontSize: "1.1rem",
    paddingLeft: "40px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
      paddingLeft: 0,
    },
  },
}))

const NocodeLandingPage = ({ data, location }) => {
  const classes = useStyles()

  return (
    <Layout>
      <HeroSection>
        <Hero
          title="Translation in no code workflows"
          subtitle="Use DeepL in your no code workflows"
        />
      </HeroSection>

      <Container maxWidth="lg" className={classes.noCodeArea}>
        <Grid container justify="space-around" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h2" className={classes.h2}>
              Automate Translation Workflows with Zapier
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9} md={6}>
            <HomeWebAppsZapierImage />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" className={classes.textRight}>
              Simpleen enables you to easily connect <strong>DeepL</strong> to
              other WebApps. Use it with Zapier to integrate machine translation
              into your Custom <strong>NoCode</strong> workflow. <br />
              Optimize your workflow even further by adding{" "}
              <strong>customized</strong> translation into your process by using
              your glossary.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h2" className={classes.h2}>
              Example Workflows
            </Typography>
            <Helmet>
              <script
                async
                src="https://zapier.com/apps/embed/widget.js?services=simpleen-translation&html_id=zapier-integration"
              ></script>
            </Helmet>
            <div id="zapier-integration"></div>
          </Grid>
        </Grid>
      </Container>

      <Seo
        general={{
          title: "Translate i18next JSON formats",
          description:
            "Translate i18next JSON formats directly, including interpolation and pluralization",
          path: location.pathname,
          image: data.twitter.childImageSharp.gatsbyImageData.src,
        }}
      />
    </Layout>
  )
}

export const vueLP3Query = graphql`
  query NocodeLP5Query {
    twitter: file(relativePath: { eq: "seo/SEOImageDefault.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          quality: 95
          placeholder: BLURRED
          layout: FIXED
        )
      }
    }
  }
`

export default NocodeLandingPage
