import React from 'react';
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";

export const HomeWebAppsZapierImage = () => {
  const data = useStaticQuery(graphql`query HomeWebAppsZapierImageQuery {
  placeholderImage: file(relativePath: {eq: "home/IllustrationWorkflow.png"}) {
    childImageSharp {
      gatsbyImageData(width: 700, quality: 95, placeholder: TRACED_SVG, layout: CONSTRAINED)
    }
  }
}
`)

  return (
    <GatsbyImage
      image={data.placeholderImage.childImageSharp.gatsbyImageData}
      alt="Translation between Apps with Simpleen" />
  );
}
